<template>
  <div
    class="flex flex-col justify-center  text-center w-full md:w-1/2 mx-auto pt-10 pb-24"
  >
  <img
        src="@/assets/muala-unofficial.svg" alt="logo Muala unofficial" class="w-48 mx-auto" 
      />    
      
      <div class="text-gray-600 text-left p-5">
      <p>
        Siemano! To co widzicie nie jest oficjalną stroną Ksiazula, ale kto wie,
        co przyniesie przyszłość?
      </p>
      <br />
      <p>
        Wziąłem i zrobiłem to całe cudo z czystej pasji, oddając w to moje
        prywatne popołudnia i poranki. Pewnego dnia, obudziłem się, pomyślałem:
        "Ej, ale by mi się przydała taka mapa, co nie?" I tak oto wpadłem na ten
        pomysł – nic wielkiego, ale czuję, że i wam się spodoba.
      </p>
      <br />
      <p>
        Słyszałem, że ostatnio pojawiło się więcej takich fanów dobrego żarcia,
        co też kombinują podobne rzeczy – mega! Mam zamiar to cudo aktualizować,
        dodawać nowe miejscówki, zaczynając Książula, a potem lecimy z
        miejscowkami od Wojka…
      </p>
      <br />
      <p>Smacznego!</p>

      
    </div>

    <div>
      
      <a href="https://buycoffee.to/mr.tomato" target="blank" class="text-orange-500  font-bold transition duration-300 border-2 border-orange-500  hover:bg-orange-500 hover:text-white rounded-full px-5 py-2 mx-2">
            wesprzyj projekt
            </a>

    </div>

    <div class="fixed inset-x-0 bottom-0 flex justify-center pb-4">
      <div
        @click="goToHomePage()"
        class="rounded-full cursor-pointer bg-orange-500 p-2 w-12 h-12 text-white hover:text-opacity-55 transition duration-300 flex justify-center items-center"
      >
        <span class="material-icons-round">close</span>
      </div>
    </div>
  </div>
</template>

<router-link
  :to="{ name: 'opis' }"
  class="hover:text-orange-500 transition duration-300"
  @click="isMenu = false"
>
  o pomyśle
</router-link>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const goToHomePage = () => {
  router.push({ name: "map" });
};
</script>
