<template>
  <div
    class="w-full min-h-screen flex flex-col justify-center items-center p-2"
    style="background-color: #bbe2c6"
  >
    <div>
      <div class="p-2 pb-10">
        <img
          src="@/assets/muala-unofficial.svg"
          alt="logo Muala unofficial"
          class="w-48 mx-auto"
        />

        <h1
          class="text-5xl sm:text-5xl md:text-6xl font-bold sm:font-black mx-4 -mt-2 py-5 px-3 -rotate-2"
        >
          Mapa Muala
        </h1>
        <p class="-mt-5 -rotate-2 font-bold">
          <strong>od Fana dla Fanów</strong>
        </p>
        <h2 class="text-2xl sm:text-1xl font-bold text-black -mt-2 py-5">
          Miejsca które odwiedzili Książulo i Wojek, te zwykłe i te muala.
        </h2>
        <p>#muala #ksiazulotracker #ksiazulo #wojek #lista&nbsp;#mapa</p>

        <button
          @click="close()"
          class="transition ease-in-out rounded-full bg-orange-500 px-10 py-3 font-bold mt-5 duration-300 hover:scale-110 hover:-rotate-2"
        >
          Zobacz mapę
        </button>
      </div>

      <div class="border-t-2 pb-10 border-dashed border-white rounded-b-xl">
        <div
          id="menu"
          class="overflow-auto flex justify-center items-center mt-10 text-black font-bold"
        >
          <div
            class="flex flex-col sm:flex-row gap-y-[10px] justify-center text-xs"
          >
            <!-- <a
              href="https://buycoffee.to/mr.tomato"
              target="blank"
              class="hover:bg-orange-500 hover:border-orange-500 transition duration-300 border-2 border-white bg-white rounded-full px-5 py-2 mx-2"
            >
              wesprzyj projekt
            </a> -->


            <router-link
              :to="{ name: 'wsparcie' }"
              class="hover:bg-orange-500 hover:border-orange-500 transition duration-300 border-2 border-white bg-white rounded-full px-5 py-2 mx-2"
            >
            wesprzyj projekt
            </router-link>


            <router-link
              :to="{ name: 'opis' }"
              class="hover:text-orange-500 transition duration-300 border-2 border-white hover:bg-white rounded-full px-5 py-2 mx-2"
            >
              o pomyśle
            </router-link>
          </div>
        </div>

        <div class="w-full z-20 text-xs text-black pt-5 text-center x-auto">
          <div>
            made with ❤️ × 🌯 a flavor blast in every byte!<br />
            <a
              href="mailto:kebab@muala.app"
              class="hover:text-orange-500 transition duration-300 px-5 py-2 mx-2"
            >
              kebab@muala.app
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  firstVisit: Boolean,
});

const emits = defineEmits(["update:firstVisit"]);

const close = () => {
  emits("update:firstVisit", false);
};
</script>
