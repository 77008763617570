<template>
  <transition name="fade">
    <div v-if="!isMapReady" class="mapPlaceholder">
      <div class="loading-container">
        <div class="loader"></div>
      </div>
    </div>
  </transition>

  <GoogleMap
    ref="googleMapRef"
    api-key="AIzaSyBT2XliB4g9bw_0tBrwy_rptJ7HPdMZ3v0"
    :center="props.center"
    :zoom="props.zoom"
    :options="{ mapTypeControl: false, backgroundColor: '#FF0000' }"
    mapId="29d876e44a2afeef"
    class="z-20 h-screen w-full"
    :libraries="['marker']"
  >
    <CustomMarker
      v-if="currentPosition"
      :options="{ position: currentPosition, anchorPoint: 'CENTER' }"
    >
      <div>
        <img :src="iconSrc" width="25" height="25" />
      </div>
    </CustomMarker>

    <MarkerCluster :options="clusterOptions">
      <Marker
        v-for="(location, i) in locations"
        :options="{
          position: location,
          label:
            location.status === 'closed'
              ? '✖️'
              : location.isMuala
              ? '😋'
              : '🔴',
        }"
        @click="selectMarker(location.id)"
        :key="i"
      >
       
      </Marker>
    </MarkerCluster>

  </GoogleMap>
</template>

<script setup>
import { ref, onMounted, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";

import {
  GoogleMap,
  Marker,
  MarkerCluster,
  CustomMarker,
} from "vue3-google-map";
import clusterIcon from "@/assets/cluster-128.png";
import currentLocationIcon from "@/assets/current-position.png";

import { prepareUrlName } from "@/composables/prepareUrlName.js";

const router = useRouter();

const props = defineProps({
  selectedMarkerId: Number,
  selectedMarker: Object,
  markers: Array,
  center: Object,
  zoom: Number,
  isMapReady: Boolean,
});

const zoomNew = ref(props.zoom || 13);

const emit = defineEmits([
  "update:selectedMarkerId",
  "update:selectedMarker",
  "update:center",
  "update:isMapReady",
  "update:zoom",
]);


const iconSrc = ref(currentLocationIcon);
const currentPosition = ref(null);
const googleMapRef = ref(null);
const isMapReady = ref(props.isMapReady);

watch([() => googleMapRef.value?.ready], ([ready]) => {
  if (!ready) {
    return;
  } else {
    setTimeout(() => {
      isMapReady.value = true;
      emit("update:isMapReady", true);
    }, 1500);
  }
});

onMounted(() => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        currentPosition.value = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      },
      (error) => {
        console.error("Geolocation error:", error);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
});

const locations = ref(
  props.markers.map((item) => ({
    ...item.position,
    id: item.id,
  }))
);

// Watch for changes in markers and update locations accordingly
watchEffect(() => {
  locations.value = props.markers.map((item) => ({
    ...item,
    ...item.position,
  }));
});

// Definicja niestandardowego renderera
const customRenderer = {
  render({ count, position }) {
    // Tworzenie elementu HTML dla klastra
    const clusterDiv = document.createElement("div");
    clusterDiv.className = "cluster-marker";
    clusterDiv.innerHTML = `<span class="cluster-text">${count}</span>`;

    // Tworzenie niestandardowej ikony
    const icon = {
      url: clusterIcon,
      scaledSize: new google.maps.Size(64, 64),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(32, 32),
      labelOrigin: new google.maps.Point(32, 32),
    };

    // Tworzenie markera dla klastra z niestandardową ikoną
    const marker = new google.maps.Marker({
  
      position,
      icon,
      label: {
        text: String(count),
        color: "#FFFFFF",
        fontSize: "15px",
        fontWeight: "bold",
      },
    });

    return marker;
  },
};

function goToLink(res) {
  const url = `/${prepareUrlName(res.adres.city)}/${prepareUrlName(
    res.title
  )}.html`;
  router.push(url);
}

const selectMarker = (id) => {
  const foundMarker = props.markers.find((marker) => marker.id === id);
  goToLink(foundMarker);
};

const clusterOptions = {
  renderer: customRenderer,
  onClusterClick: (event, cluster, map) => {

    // Centrowanie mapy na klastrze
    if (typeof cluster.getCenter === "function") {
      const center = cluster.getCenter();
      console.log("center cluster :", center);
      map.panTo(center);
      console.log("center 1:", center);
    } else if (cluster._position) {
      const center = {
        lat: cluster._position.lat(),
        lng: cluster._position.lng(),
      };
      map.panTo(center);
    } else {
      console.log("Nie można znaleźć centrum klastra");
    }


    // Zawsze przybliża o 2 poziomy, niezależnie od aktualnego zoomu (bez przekraczania maksymalnego poziomu zoomu mapy)
    const newZoom = Math.min(map.getZoom() + 4, map.maxZoom || 20); // Używa maksymalnego zoomu mapy lub 20 jako fallback
    map.setZoom(newZoom);
  },
};
</script>

<style>
.gm-fullscreen-control,
.gm-style-mtc,
.gm-svpc {
  display: none;
}

.cluster-marker {
  position: absolute;
  width: 64px;
  height: 64px;
  text-align: center;
  line-height: 64px;
}

.cluster-text {
  display: block;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.mapPlaceholder {
  background-color: #bbe2c6;
  background-size: auto 100%;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  z-index: 2;
  padding-bottom: 200px;
}

.loading-container {
  display: flex;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* czekolada */
.loader {
  width: 35px;
  height: 80px;
  position: relative;
}
.loader:before {
  content: "";
  position: absolute;
  inset: 0 0 20px;
  padding: 1px;
  background: conic-gradient(
      from -90deg at calc(100% - 3px) 3px,
      #7e3c26 135deg,
      #341612 0 270deg,
      #0000 0
    ),
    conic-gradient(
      from 0deg at 3px calc(100% - 3px),
      #0000 90deg,
      #341612 0 225deg,
      #7e3c26 0
    ),
    #54281f;
  background-size: 17px 17px;
  background-clip: content-box;
  --c: no-repeat linear-gradient(#000 0 0);
  -webkit-mask: var(--c) 0 0, var(--c) 17px 0, var(--c) 0 17px,
    var(--c) 17px 17px, var(--c) 0 34px, var(--c) 17px 34px,
    linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: l6 3s infinite;
}
.loader:after {
  content: "";
  position: absolute;
  inset: 60% 0 0;
  background: #b21b01;
  border-top: 5px solid #fff;
}
@keyframes l6 {
  0%,
  14% {
    -webkit-mask-size: 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, auto;
  }
  15%,
  29% {
    -webkit-mask-size: 17px 17px, 0 0, 0 0, 0 0, 0 0, 0 0, auto;
  }
  30%,
  44% {
    -webkit-mask-size: 17px 17px, 17px 17px, 0 0, 0 0, 0 0, 0 0, auto;
  }
  45%,
  59% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 0 0, 0 0, 0 0, auto;
  }
  60%,
  74% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 17px 17px, 0 0, 0 0,
      auto;
  }
  75%,
  89% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 17px 17px, 17px 17px,
      0 0, auto;
  }
  90%,
  100% {
    -webkit-mask-size: 17px 17px, 17px 17px, 17px 17px, 17px 17px, 17px 17px,
      17px 17px, auto;
  }
}

</style>
