import { replacePolishCharacters } from "@/composables/replacePolishCharacters";
import { generateOrthos } from "@/composables/misspelling";


function correctWords(searchWordsArray, mistakes) {
    let correctedWords = searchWordsArray.map(word => {
        let correctedWord = word;

        Object.entries(mistakes).forEach(([correct, incorrects]) => {
            incorrects.some(incorrect => {
                if (correctedWord.includes(incorrect)) {
                    correctedWord = correctedWord.replace(incorrect, correct);
                    return true;
                }
                return false;
            });
        });
        return correctedWord;
    });

    return correctedWords;
}




export function triggerFiltering(searchValue, allMarkers, mistakesDict) {
    let markersClear = allMarkers; 
  
  
    // 🟢🟢 wyszukanie ogolne wszystkie moga byc partail matches
    // if (searchValue && searchValue.trim()) {
    //   const searchWordsArray = replacePolishCharacters(searchValue).toLowerCase().trim().split(' ');
    //   // console.log('searchValue:', searchValue)
    //   markersClear = markersClear.filter(marker => {
    //     const markerText = [
    //       marker.title, 
    //       marker.adres.city, 
    //       marker.adres.street, 
    //       marker.adres.postcode, 
    //       marker.shortDesc, 
    //       marker.text,
    //       ...marker.type
    //     ].join(' ').toLowerCase();
  
    //     const markerTextNormalized = replacePolishCharacters(markerText);
    //     return searchWordsArray.every(term => markerTextNormalized.includes(term));
    //     // return sortTermsByMatch(searchWordsArray, markerTextNormalized)
    //   });
    // }
  
  
  // 🟢🟢 wyszukiwanie ogolne exact + partial matches
  // if (searchValue && searchValue.trim()) {
  //     const searchWordsArray = replacePolishCharacters(searchValue).toLowerCase().trim().split(' ');
  
  //     markersClear = markersClear.map(marker => {
  //         const markerText = [
  //             marker.title,
  //             marker.adres.city,
  //             marker.adres.street,
  //             marker.adres.postcode,
  //             marker.shortDesc,
  //             marker.text,
  //             ...marker.type
  //         ].join(' ').toLowerCase();
  
  //         const markerTextNormalized = replacePolishCharacters(markerText);
  
  //         let matchesInfo = searchWordsArray.map(term => {
  //             const regexExact = new RegExp(`\\b${term}\\b`, 'i');
  //             const regexPartial = new RegExp(term, 'i');
  //             return {
  //                 exactMatch: regexExact.test(markerTextNormalized),
  //                 partialMatch: regexPartial.test(markerTextNormalized)
  //             };
  //         });
  
  //         return {
  //             ...marker,
  //             exactMatches: matchesInfo.filter(info => info.exactMatch).length,
  //             partialMatches: matchesInfo.filter(info => info.partialMatch).length,
  //             allTermsMatch: matchesInfo.every(info => info.exactMatch || info.partialMatch)
  //         };
  //     })
  //     .filter(marker => marker.allTermsMatch) // Usuń markery, które nie mają wszystkich słów pasujących
  //     .sort((a, b) => {
  //         // Sortowanie po liczbie dokładnych i częściowych trafień
  //         if (b.exactMatches !== a.exactMatches) {
  //             return b.exactMatches - a.exactMatches;
  //         }
  //         return b.partialMatches - a.partialMatches;
  //     });
  
  //     console.log('🟦🟦 markersClear:', markersClear);
  // }
  
  // 🟢🟢 wyszukanie ogolne exact matches
  if (searchValue && searchValue.trim()) {
      const searchWordsArray = replacePolishCharacters(searchValue).toLowerCase().trim().split(' ');
  
      markersClear = markersClear.map(marker => {
          const markerText = [
              marker.title,
              marker.adres.city,
              marker.adres.street,
              marker.adres.postcode,
              marker.shortDesc,
              marker.text,
              ...marker.type
          ].join(' ').toLowerCase();
  
          const markerTextNormalized = replacePolishCharacters(markerText);
  
          // Sprawdzanie dokładnych trafień dla każdego słowa
          const allExactMatches = searchWordsArray.every(term => {
              const regexExact = new RegExp(`\\b${term}\\b`, 'i');
              return regexExact.test(markerTextNormalized);
          });
  
          return {
              ...marker,
              allExactMatches
          };
      })
      .filter(marker => marker.allExactMatches) // Zachowaj tylko markery z dokładnymi trafieniami dla wszystkich słów
      // Możesz jednak dodać własną logikę sortowania, jeśli chcesz sortować markery na podstawie innych kryteriów  
  }
  
  
  
  
  
    /// 🟢🟢 ortograficzne bledy z generateOrthos
    if (searchValue && markersClear.length === 0) {
      markersClear = allMarkers; 
      // console.log('🔴 Nie znaleziono wyników szukamy z ortograficznymi...')
  
      const searchWordsArray = searchValue.split(' ');
      // const searchWordsArrayCorrect = correctWords(searchWordsArray, mistakesDict.value)
      
      let orthosWords = [];
      searchWordsArray.forEach((word) => {
        const array = generateOrthos(word);
        // Tworzenie obiektu z dynamicznym kluczem i przypisanie tablicy jako wartości
        orthosWords.push(array);
      });
  
  
      // console.log('🔴 searchValue:', searchValue)
      // console.log('🔴 markersClear.length:', markersClear.length)
      // console.log('🔴 searchWordsArray:', searchWordsArray)
      // console.log('🔴 orthosWords:', orthosWords)
  
  
      
      markersClear = markersClear.filter(marker => {
        const markerText = [
          marker.title, 
          marker.adres.city, 
          marker.adres.street, 
          marker.adres.postcode, 
          marker.shortDesc, 
          marker.text,
          ...marker.type
        ].join(' ').toLowerCase();
  
  
        let result = orthosWords.every(subArray => 
          subArray.some(term => markerText.includes(term))
        );
  
        return result;
  
      });
  
    }
  
  
  
  
  
    // 🟢🟢 misspelling bledy z mistakesDict
    if (searchValue && markersClear.length === 0) {
      markersClear = allMarkers; 
      // console.log('🔴 Nie znaleziono wyników szukamy z bledami...')
  
      const searchWordsArray = searchValue.split(' ');
      const searchWordsArrayCorrect = correctWords(searchWordsArray, mistakesDict)
      
      // console.log('🔴 searchValue:', searchValue)
      // console.log('🔴 markersClear.length:', markersClear.length)
      // console.log('🔴 searchWordsArray:', searchWordsArray)
      // console.log('🔴 searchWordsArrayCorrect:', searchWordsArrayCorrect)
  
      
      markersClear = markersClear.filter(marker => {
        const markerText = [
          marker.title, 
          marker.adres.city, 
          marker.adres.street, 
          marker.adres.postcode, 
          marker.shortDesc, 
          marker.text,
          ...marker.type
        ].join(' ').toLowerCase();
  
        return searchWordsArrayCorrect.every(term => markerText.includes(term));
  
      });
  
    }
  
    // console.log('🟦🟦 markersClear.length:', markersClear.length)
    // emits("update:search", searchValue);
    return markersClear;
    
  }


