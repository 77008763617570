<template>
<div class="flex flex-col justify-center items-center h-screen text-center">
  <div class="text-8xl text-gray-200 font-extrabold pb-5">
   4🍽️4
  </div>
  <div class="text-gray-600">
    Zgubiłeś się jak pieróg w barszczu.<br>Ale spoko, zdarza się najlepszym.
  </div>
  <div class="pt-7">
    <router-link :to="{ name: 'map' }" class="px-7 py-3 cursor-pointer bg-gray-100 font-bold text-slate-600 hover:bg-orange-500 hover:text-white rounded-full transition duration-300">
      powrót do smaków
    </router-link>
  </div>
</div>


</template>
