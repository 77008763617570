<template>
  <div
    class="z-30 fixed bottom-5 left-1/2 transform -translate-x-1/2 bg-orange-500 text-white font-medium rounded-full shadow-lg py-3 px-8 flex justify-between items-center max-w-full w-650 backdrop-blur-sm"
  >
    <div class="menuIcons" @click="showMap()">
      <span class="material-icons-round">location_on</span>
    </div>

    <div class="menuIcons relative" @click="toggleList()">
      <span
        v-if="props.search"
        class="absolute z-40 flex right-1 top-4 h-3 w-5"
      >
        <span
          class="absolute inline-flex h-full w-full bg-white opacity-80"
        ></span>
      </span>
      <span class="material-icons-round mr-0 ml-3">receipt</span>
    </div>

    <div class="menuIcons relative" @click="onlyMuala(filtersData, allMarkers)">
      <span class="z-30 flex bottom-0 h-10">
        <a id="muala-logo" class="cursor-pointer w-16">
          <img
            src="@/assets/M-icon.svg"
            alt="tylko MUALA"
            style="width: 100%; height: 100%"
          />
        </a>
      </span>
    </div>

    <div class="menuIcons relative">
      <span
        v-if="isFiltered"
        class="absolute z-30 flex right-2 bottom-0 h-2 w-2"
      >
        <span
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"
        ></span>
        <span class="relative inline-flex rounded-full h-2 w-2 bg-white"></span>
      </span>
      <span class="material-icons-round mr-3 ml-0" @click="toggleFilter()"
        >tune</span
      >
    </div>

    <div class="menuIcons">
      <span class="material-icons-round ml-o">
        <!-- <a href="https://buycoffee.to/mr.tomato" target="_blank"
          >monetization_on</a
        > -->


        <router-link
              :to="{ name: 'wsparcie' }"
            >
            monetization_on
            </router-link>


      </span>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { triggerFiltering } from "@/composables/triggerFiltering";
import { ref } from "vue";
import { applyFilters } from "@/composables/applyFilters.js";

const route = useRoute();
const router = useRouter();

const props = defineProps({
  isFilterVisible: Boolean,
  isListVisible: Boolean,
  selectedMarker: Object,
  markers: Array,
  filtersData: {
    type: Array,
    default: () => [],
  },
  search: String,
  isFiltered: Boolean,
  allMarkers: Array,
  mistakesDict: Object,
});

const emit = defineEmits([
  "update:isFilterVisible",
  "update:isListVisible",
  "update:selectedMarker",
  "update:markers",
  "update:allMarkers",
  "update:filtersData",
  "update:isFiltered",
]);

const markers = ref([...props.markers]);

const toggleFilter = () => {
  const isFilterVisible = !props.isFilterVisible;
  emit("update:isFilterVisible", isFilterVisible);
};

const toggleList = () => {
  const isListVisible = !props.isListVisible;
  emit("update:isListVisible", isListVisible);
};

const onlyMuala = (array, allMarkers) => {
  const filtersData = array.map((item) => {
    if (item.id === 1) {
      return { ...item, enabled: true };
    }
    return { ...item, enabled: false };
  });

  const updatedMarkers = applyFilters(filtersData, allMarkers);
  emit("update:markers", updatedMarkers);
  emit("update:filtersData", filtersData);
};

const showMap = () => {
  if (route.params.restaurantName) {
    emit("update:selectedMarker", null);
    router.push(`/${route.params.city}/`);
    markers.value = triggerFiltering(
      props.search,
      markers.value,
      props.allMarkers
    );
    emit("update:markers", markers.value);
  }

  emit("update:isFilterVisible", false);
  emit("update:isListVisible", false);
};
</script>

<style>
.menuIcons .material-icons-round {
  @apply text-3xl;
}

.menuIcons {
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply cursor-pointer;
  @apply hover:opacity-55;
  @apply transition;
  @apply duration-300;
}
</style>
