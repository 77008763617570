export const msg = `

███████ ██       ██████  ██     ██████  ███████ ██    ██  
██      ██      ██    ██ ██     ██   ██ ██      ██    ██  
█████   ██      ██    ██ ██     ██   ██ █████   ██    ██  
██      ██      ██    ██        ██   ██ ██       ██  ██   
███████ ███████  ██████  ██     ██████  ███████   ████    
                                                          
                                                          
███████ ██       ██████  ██████  ███████ ██████   ██████  
██      ██      ██    ██ ██   ██ ██      ██   ██ ██    ██ 
█████   ██      ██    ██ ██████  █████   ██████  ██    ██ 
██      ██      ██    ██ ██      ██      ██   ██ ██    ██ 
███████ ███████  ██████  ██      ███████ ██   ██  ██████  
                                                                                                                   

`