export function generateOrthos(word) {
  const mistakesSet = new Set();

  const orthographicReplacements = {
    'ó': 'u',
    'u': 'ó',
    'rz': 'ż',
    'ż': 'rz',
    'z': 'ż',
    // Dodaj więcej zasad zgodnie z potrzebami
  };

  // Funkcja pomocnicza do generowania wszystkich kombinacji na podstawie zasad
  function generateMistakes(word, start = 0) {
    if (start >= word.length) return;

    Object.entries(orthographicReplacements).forEach(([incorrect, correct]) => {
      let index = word.indexOf(incorrect, start);
      while (index !== -1) {
        const newWord = word.substring(0, index) + correct + word.substring(index + incorrect.length);
        if (!mistakesSet.has(newWord)) {
          mistakesSet.add(newWord);
          generateMistakes(newWord, index + correct.length); // Rekurencyjne generowanie dalszych błędów z nowego słowa
        }
        index = word.indexOf(incorrect, index + 1); // Szukaj dalej w tym samym słowie
      }
    });
  }

  // Rozpoczęcie generowania błędów od początku słowa
  generateMistakes(word);
  return Array.from(mistakesSet);
}



export function misspelling(word) {
  const uniqueTyposSet = new Set();
  let unchangedSizeCount = 0; // Licznik iteracji bez zmian w rozmiarze zestawu
  let lastSize = 0; // Ostatni rozmiar zestawu, do porównania

  // Funkcja generująca literówki
  function generateTypos(word) {
    const keyboardLayouts = {
      polish: {
        q: ["w", "a"],
        w: ["q", "e", "s"],
        z: ["x", "s", "a"],
        ł: ["k", "l"],
        b: ["p"],
        e: ["a"],
        u: ['ó'],
        ó: ['u'],
        "-": [" "],
        " ": ["-"],
        ń: ['n'],
        ą: ['a'], 
        ć: ['c'], 
        ę: ['e'], 
        ń: ['n'], 
        ó: ['o'], 
        ś: ['s'], 
        ż: ['z'], 
        ź: ['z'],
      },
    };

    const layout = keyboardLayouts["polish"];

    function replaceAt(string, index, replacement) {
      return string.substr(0, index) + replacement + string.substr(index + 1);
    }

    let typo = word;
    const randomIndex = Math.floor(Math.random() * word.length);
    const randomAction = Math.floor(Math.random() * 5);

    switch (randomAction) {
      case 0:
        const nearbyKeys = layout[word[randomIndex]] || [];
        if (nearbyKeys.length === 0) {
          break;
        }
        const randomKey =
          nearbyKeys[Math.floor(Math.random() * nearbyKeys.length)];
        typo = replaceAt(typo, randomIndex, randomKey);
        break;
      case 1:
        typo = typo.slice(0, randomIndex) + typo.slice(randomIndex + 1);
        break;
      case 2:
        const addKey =
          Object.keys(layout)[
            Math.floor(Math.random() * Object.keys(layout).length)
          ];
        typo = replaceAt(typo, randomIndex, word[randomIndex] + addKey);
        break;
      case 3:
        if (randomIndex < word.length - 1) {
          typo = replaceAt(
            replaceAt(typo, randomIndex, word[randomIndex + 1]),
            randomIndex + 1,
            word[randomIndex]
          );
        }
        break;
      case 4:
        typo = typo.replace(/\./g, " ");
        break;
    }
    return typo;
  }

  // Generowanie unikalnych literówek
  let counterIterations = 0;
  while (uniqueTyposSet.size < 200 && unchangedSizeCount < 250) {
    counterIterations++;
    const generatedTypo = generateTypos(word);
    uniqueTyposSet.add(generatedTypo);

    if (uniqueTyposSet.size === lastSize) {
      unchangedSizeCount++;
    } else {
      unchangedSizeCount = 0;
      lastSize = uniqueTyposSet.size;
    }
  }
// console.log("counterIterations:", word, counterIterations);
  generateOrthos(word) // Dodaj błędy ortograficzne
    .forEach(w => uniqueTyposSet.add(w));

  return Array.from(uniqueTyposSet);
}