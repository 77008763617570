import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';



const firebaseConfig = {
  apiKey: "AIzaSyBSpymKJg1r_tsy2eOxqZOdHtxGyYwkrQc",
  authDomain: "muala-418f5.firebaseapp.com",
  projectId: "muala-418f5",
  storageBucket: "muala-418f5.appspot.com",
  messagingSenderId: "1061485017022",
  appId: "1:1061485017022:web:3f9e82e629762368576bef"
};

// init firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore();
const auth = getAuth();

export { db, auth };
