<template>
  <div
    id="filters"
    class="fixed top-0 left-0 z-50 overflow-auto flex justify-center items-start pt-0 sm:pt-20 pb-20 bg-white text-black font-bold text-lg h-screen"
  >
    <div class="flex flex-wrap gap-x-[10px] gap-y-[35px] justify-center">
      <h2 class="w-full text-2xl font-bold text-black mx-4 mt-5 py-1 px-3">
        Rodzaj jedzenia
      </h2>

      <div
        class="flex flex-wrap items-start pl-11 pr-11 w-full md:w-9/10 lg:w-3/4"
      >
        <div
          v-for="item in filtersData"
          :key="item.id"
          class="w-full md:w-1/3 lg:w-1/4 p-1 sm:p-3 whitespace-nowrap"
        >
          <SwitchGroup as="div" class="flex items-center mb-2">
            <Switch
              v-model="item.enabled"
              :class="[
                item.enabled ? 'bg-orange-500' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2',
              ]"
              @click="item.id === 0 ? filterDisableAll() : null"
            >
              <span
                aria-hidden="true"
                :class="[
                  item.enabled ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                ]"
              />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span
                v-if="item.id === 0 || item.id === 1 || item.id === 10"
                class="text-sm font-medium text-gray-900"
                ><strong>{{ item.text }}</strong>
              </span>
              <span v-else class="text-sm font-medium text-gray-900"
                >{{ item.text }}
              </span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
      </div>

      <h2 class="w-full text-2xl font-bold text-black mx-4 mt-4 py-1 px-3">
        Wybierz miasto
      </h2>
      <ul class="mb-20">
        <li
          @click="navigateToCity(city)"
          v-for="city in citiesData.slice(0, 6)"
          :key="city.id"
          :class="`cursor-pointer text-sm text-slate-600 rounded-full py-1 px-4 mx-1 my-1 inline-block ${
            props.search === city.text
              ? 'bg-orange-500 text-white'
              : 'bg-gray-100 hover:bg-orange-500 hover:text-white'
          }`"
        >
          {{ city.text }}
        </li>
      </ul>

      <div class="fixed inset-x-0 bottom-0 flex justify-center pb-4">
        <div
          @click="toggleFilter(filtersData, allMarkers, isFilterVisible)"
          class="rounded-full cursor-pointer bg-orange-500 p-2 w-12 h-12 text-white hover:text-opacity-55 transition duration-300 flex justify-center items-center"
        >
          <span class="material-icons-round">close</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { locationRoutes } from "../data/locationRoutes";
import { useRouter } from "vue-router";
import { applyFilters } from "@/composables/applyFilters.js";

const router = useRouter();

const props = defineProps({
  isFilterVisible: Boolean,
  isListVisible: Boolean,
  allMarkers: Array,
  filtersData: {
    type: Array,
    default: () => [],
  },
  markers: Array,
  search: String,
  zoom: Number,
});

const emits = defineEmits([
  "update:isFilterVisible",
  "update:allMarkers",
  "update:filtersData",
  "update:markers",
  "update:selectedMarker",
  "update:center",
  "update:isListVisible",
  "update:search",
  "update:zoom",
]);

const markersByCity = (city) => {
  const markers = props.allMarkers.filter(
    (item) => item.adres.city.toLowerCase() === city.toLowerCase()
  );
  emits("update:markers", markers);
};

const navigateToCity = (city) => {
  router.push(city.path);
  markersByCity(city.text);
  emits("update:search", city.text);
  emits("update:center", city.position);
  emits("update:isListVisible", false);
  emits("update:zoom", 13);
  // emits("update:isFilterVisible", false);
  toggleFilter(props.filtersData, props.allMarkers, props.isFilterVisible);
};

const citiesData = ref(locationRoutes);

function filterDisableAll() {
  props.filtersData.forEach((item) => {
    item.enabled = false;
  });
  emits("update:filtersData", props.filtersData);
}

const toggleFilter = (filtersData, allMarkers, isFilterVisible) => {
  const isFilter = !isFilterVisible;
  const updatedMarkers = applyFilters(filtersData, allMarkers);
  emits("update:markers", updatedMarkers);
  emits("update:isFilterVisible", isFilter);
  emits("update:isListVisible", false);
};
</script>

<style>
#filters a {
  padding: 10px 20px;
  border: 0px;
  border-radius: 30px;
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  margin: 7px 3px 7px 3px;
  background-color: #e9e9e9;
}

#filters a:hover {
  background-color: #c9c9c9;
  transition-duration: 0.25s;
}
</style>
