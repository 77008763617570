<template>
  <div
    id="list"
    class="fixed w-full top-0 left-0 z-30 overflow-auto flex justify-center items-start pt-10 pb-20 bg-white text-black font-bold text-lg h-screen"
  >
    <div class="flex w-full flex-wrap gap-x-[10px] gap-y-[35px] justify-center">
      <div class="bg-white w-full">
        <div class="mx-auto max-w-7xl py-12 px-6 lg:px-8 lg:py-24">
          <div class="space-y-12">
            <div
              class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none mx-auto"
            >
              <div
                class="flex items-center w-full sm:w-2/3 mx-auto relative text-black"
              >
                <span
                  v-if="searchWords === ''"
                  class="material-icons-round p-2 absolute left-2 rounded-full bg-gray-100"
                  >search</span
                >
                <span
                  v-else
                  @click="clearSearch()"
                  class="material-icons-round p-2 cursor-pointer absolute left-2 rounded-full bg-gray-100 hover:bg-gray-200 transition duration-300 ease-in-out"
                  >close</span
                >

                <input
                  v-model="searchWords"
                  type="text"
                  class="w-full text-xl border-none rounded-full bg-gray-100 px-6 py-3 pl-12 focus:outline-none focus:ring-2 focus:ring-orange-500 placeholder-normal"
                  placeholder="Szukaj, co tam dusza zapr..."
                />

                
              </div>

              <span v-if="!ortoMistake">
                <h2
                  v-if="
                    searchWords !== '' && sortedAndFilteredMarkers.length > 0
                  "
                  class="w-full text-2xl font-bold text-black mt-10 py-1 px-3"
                >
                  Ooo, znalazłem, znalazłem! ({{
                    sortedAndFilteredMarkers.length
                  }})
                </h2>
                <h2
                  v-if="
                    searchWords !== '' && sortedAndFilteredMarkers.length == 0
                  "
                  class="w-full text-2xl font-bold text-black mt-10 py-1 px-3"
                >
                  <div class="pb-3">A moze coś pyszniutkiego?</div>
                  <a
                    @click="searchWords = 'kebab'"
                    class="bg-gray-100 hover:bg-orange-500 hover:text-white cursor-pointer text-sm text-slate-600 rounded-full py-1 px-4 mx-1 my-1 inline-block"
                    >🌯
                  </a>
                  <a
                    @click="searchWords = 'MUALA'"
                    class="bg-gray-100 hover:bg-orange-500 hover:text-white cursor-pointer text-sm text-slate-600 rounded-full py-1 px-4 mx-1 my-1 inline-block"
                    >MUALA</a
                  >
                  <a
                    @click="searchWords = 'burger'"
                    class="bg-gray-100 hover:bg-orange-500 hover:text-white cursor-pointer text-sm text-slate-600 rounded-full py-1 px-4 mx-1 my-1 inline-block"
                    >🍔</a
                  >
                  <a
                    @click="searchWords = 'pizza'"
                    class="bg-gray-100 hover:bg-orange-500 hover:text-white cursor-pointer text-sm text-slate-600 rounded-full py-1 px-4 mx-1 my-1 inline-block"
                    >🍕</a
                  >
                  <a
                    @click="searchWords = 'deserowo'"
                    class="bg-gray-100 hover:bg-orange-500 hover:text-white cursor-pointer text-sm text-slate-600 rounded-full py-1 px-4 mx-1 my-1 inline-block"
                    >🍪</a
                  >
                </h2>
              </span>

              <span>
                <h2
                  v-if="
                    ortoMistake &&
                    searchWords !== '' &&
                    sortedAndFilteredMarkers.length > 0
                  "
                  class="w-full text-2xl font-bold text-black mt-10 py-1 px-3"
                >
                  {{ chooseRandomWord() }} znalazłem! ({{
                    sortedAndFilteredMarkers.length
                  }}) ale...<br />
                  poprawna pisownia to&nbsp;
                  <strong class="mark"
                    >&nbsp;{{ correctedWordsArr.join(" ") }}&nbsp;</strong
                  >
                </h2>
              </span>
            </div>

            <!-- LOADING -->
            <div v-if="!sortedAndFilteredMarkers">
              <ul
                role="list"
                class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
              >
                <li v-for="number in 15" :key="number" class="animate-pulse">
                  <a class="space-y-4 block">
                    <div class="aspect-w-16 aspect-h-9 rounded-lg bg-gray-200">
                    </div>

                    <div class="space-y-2">
                      <div
                        class="space-y-1 text-lg font-medium leading-6 text-left"
                      >
                        <h3 class="h-6 w-2/3 bg-gray-200 rounded"></h3>
                        <p class="h-4 w-4/5 bg-gray-200 rounded"></p>
                        <p class="h-2 w-1/5 pt-3 bg-gray-200 rounded-full"></p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <!-- LOADED -->
            <div v-if="sortedAndFilteredMarkers">
              
              <ul
                role="list"
                class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
              >
                <li v-for="item in displayedMarkers" :key="item.id">
                  <router-link
                    @click="toggleList()"
                    :to="`/${prepareUrlName(item.adres.city)}/${prepareUrlName(
                      item.title
                    )}.html`"
                    class="block space-y-4"
                  >

                    <div
                      class="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden relative"
                    >
                      <transition name="fade">
                        <img
                          v-if="imageLoaded[item.id]"
                          :src="`https://img.youtube.com/vi/${item.videoId}/hqdefault.jpg`"
                          class="rounded-lg object-cover bg-gray-100 hover:scale-105 transition-transform duration-300 w-full h-full"
                          :alt="item.title"
                        />
                      </transition>
                      <div
                        v-if="!imageLoaded[item.id]"
                        class="animate-pulse absolute inset-0 bg-gray-300"
                      ></div>
                    </div>
                    <div class="space-y-2">
                      <div
                        class="space-y-1 text-lg font-medium leading-6 text-left"
                      >
                        <h3>{{ item.title }}</h3>
                        <p class="text-gray-300 text-s shortdesc">
                          {{ item.shortDesc }}
                        </p>
                        <p class="text-gray-300 text-xs">
                          {{ item.adres.city }}
                        </p>
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>

              <button
                v-if="displayLimit < sortedAndFilteredMarkers.length"
                @click="displayLimit += 45"
                class="mt-8 mb-8 sm:mb-0 bg-gray-100 px-10 py-2 rounded-full hover:bg-gray-200 transition-colors duration-300"
              >
                👀👉 pokaż więcej
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  computed,
  onMounted,
  watchEffect,
  watch,
} from "vue";
import { prepareUrlName } from "@/composables/prepareUrlName.js";
import { misspelling, generateOrthos } from "@/composables/misspelling";
import { triggerFiltering } from "@/composables/triggerFiltering";

const props = defineProps({
  isFilterVisible: Boolean,
  isListVisible: Boolean,
  allMarkers: Array,
  markers: Array,
  search: String,
  mistakesDict: Object,
  isFiltered: Boolean,
  zoom: Number,
});

// Reaktywny obiekt do śledzenia stanów ładowania obrazów
const imageLoaded = reactive({});

const setImageLoaded = (id) => {
  imageLoaded[id] = true;
};

const debounceTimeout = ref(null);
const ortoMistake = ref(false);
const mistakesDict = ref(props.mistakesDict);
const searchWords = ref(props.search);
const displayLimit = ref(45);

const ortoMistakeList = {
  żurek: ["rzurek", "rzórek", "żurek"], // eeee, pasibrzuchu/bratku ale rzurek to się pisze przez rzet z kropką ;)
  rosół: ["rosuł", "rosul", "rosul"],
  schabowy: ["shabowy"],
  żurawiną: ["rzurawiną"],
  kapuśniak: ["kapóśniak"],
  gołąbki: ["golombki"],
  pączki: ["ponczki"],
  pączek: ["ponczek"],
};

const correctedWordsArr = ref([]);

function correctWordIs(searchWordsArray, mistakes) {
  let wasCorrected = false; // Flag, czy jakiekolwiek słowo zostało poprawione
  let correctedWords = searchWordsArray.map((word) => {
    let correctedWord = word;

    Object.entries(mistakes).forEach(([correct, incorrects]) => {
      incorrects.some((incorrect) => {
        if (correctedWord.includes(incorrect)) {
          correctedWord = correctedWord.replace(incorrect, correct);
          wasCorrected = true;
          return true;
        }
        return false;
      });
    });

    return correctedWord;
  });

  // Zwróć zaktualizowaną tablicę tylko jeśli co najmniej jedno słowo zostało poprawione
  return wasCorrected ? correctedWords : [];
}

const chooseRandomWord = () => {
  const words = ["eee, posibrzuchu", "O kurde, bratku", "No przyjacielu..."];
  const randomIndex = Math.floor(Math.random() * words.length);
  return words[randomIndex];
};

const emits = defineEmits([
  "update:isFilterVisible",
  "update:isListVisible",
  "update:allMarkers",
  "update:markers",
  "update:selectedMarker",
  "update:center",
  "update:search",
  "update:mistakesDict",
  "update:zoom",
]);

const filteredMarkers = ref([...props.markers]);

function sortArrayByPublishedAt(array) {
  const sortedArray = array.slice();

  sortedArray.sort((a, b) => {
    const dateA = new Date(a.publishedAt);
    const dateB = new Date(b.publishedAt);
    // return dateA - dateB; // Sortowanie rosnąco
    return dateB - dateA;
  });

  return sortedArray;
}

watch(
  () => props.search,
  (newValue) => {
    searchWords.value = newValue;
    filteredMarkers.value = sortArrayByPublishedAt(
      triggerFiltering(
        newValue,
        props.allMarkers,
        mistakesDict.value
      )
    );
  
    emits("update:search", newValue);
  },
  { immediate: true }
);


function buildTypesDictionary(markers, misspelling, generateOrthos) {
  const allTypesSet = new Set();
  markers.forEach((marker) => {
    const types = Array.isArray(marker.type) ? marker.type : [marker.type];
    types.forEach((type) => allTypesSet.add(type));
  });

  const allTypes = Array.from(allTypesSet);
  const newTypesDictionary = allTypes.reduce((acc, type) => {
    const normalizedType = type.trim().replace(/\.$/, "").toLowerCase();
    const misspellings = misspelling(normalizedType);
    const orthos = generateOrthos(normalizedType);

    acc[normalizedType] = [...misspellings, ...orthos];

    return acc;
  }, {});

  return newTypesDictionary;
}

onMounted(() => {
  if (Object.keys(mistakesDict.value).length === 0) {
    mistakesDict.value = buildTypesDictionary(
      props.allMarkers,
      misspelling,
      generateOrthos
    );
    emits("update:mistakesDict", mistakesDict.value);
  }
});

// Debouncing watch
watch(
  searchWords,
  (newValue) => {
    if (debounceTimeout.value) {
      clearTimeout(debounceTimeout.value);
    }

    // Ustawia nowy timer
    debounceTimeout.value = setTimeout(() => {
      ortoMistake.value = false;
      filteredMarkers.value = sortArrayByPublishedAt(
        triggerFiltering(
          newValue,
          props.allMarkers,
          mistakesDict.value
        )
      );

      correctedWordsArr.value = correctWordIs(
        searchWords.value.split(" "),
        ortoMistakeList
      );
      if (correctedWordsArr.value.length > 0) {
        ortoMistake.value = true;
      } else {
        ortoMistake.value = false;
      }

      emits("update:search", newValue);
    }, 500);
  },
  { immediate: true }
);

const clearSearch = () => {
  searchWords.value = "";
  emits("update:search", "");
};

const toggleFilter = () => {
  console.log("toggleFilter..");
  const isFilterVisible = !props.isFilterVisible;
  emits("update:isFilterVisible", isFilterVisible);
};

const sortedAndFilteredMarkers = computed(() => {
  let markers = filteredMarkers.value.slice();

  // markers.sort((a, b) => {
  //   const cityA = a.adres.city.toLowerCase();
  //   const cityB = b.adres.city.toLowerCase();
  //   return cityA.localeCompare(cityB);
  // });

  return markers;
});

watch(sortedAndFilteredMarkers, (newValue, oldValue) => {
  if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
    emits("update:markers", newValue);
  }
});

const toggleList = () => {
  const isListVisible = !props.isListVisible;
  emits("update:isListVisible", isListVisible);

  if (props.zoom > 16) {
    emits("update:isListVisible", 13);
  }

  emits("update:isListVisible", isListVisible);
};

// Funkcja do preloadingu obrazu i aktualizacji stanu
const preloadImageAndSetLoaded = (item) => {
  if (imageLoaded[item.id] === undefined) {
    // Inicjalizacja tylko, jeśli stan nie był wcześniej ustawiony
    imageLoaded[item.id] = false;
    const img = new Image();
    img.onload = () => {
      imageLoaded[item.id] = true;
    };
    img.src = `https://img.youtube.com/vi/${item.videoId}/hqdefault.jpg`;
  }
};

watchEffect(() => {
  // console.log("sortedAndFilteredMarkers:",sortedAndFilteredMarkers)
  sortedAndFilteredMarkers.value.forEach(preloadImageAndSetLoaded);
});

// Obliczona właściwość, która zwraca elementy do wyświetlenia
const displayedMarkers = computed(() => {
  return sortedAndFilteredMarkers.value.slice(0, displayLimit.value);
});
</script>

<style>
.placeholder-normal::placeholder {
  @apply font-normal;
}

.shortdesc::first-letter {
  text-transform: uppercase;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
