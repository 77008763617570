import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import { GoogleMap } from 'vue3-google-map';
import '@/assets/css/tailwind.css'
import 'material-icons/iconfont/material-icons.css';
import mitt from 'mitt'; 


const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.$emitter = emitter;

app.component('GoogleMap', GoogleMap);
app.use(router);
app.mount('#app');


