// firebase imports
import { db } from '../firebase/config';
import { doc, getDoc } from "firebase/firestore"; 
import getDocWithRetry from './getDocWithRetry';

const getDescriptionsById = async (c, id) => {
    const docRef = doc(db, c, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        return docSnap.data();
    } 
    
    else {
        console.log("No such document!");
        return null;
    }
}


// const getDescriptionsById = async (c, id) => {
//     console.log("getDescriptionsById INSIDE");
//     console.log("c: ", c);
//     console.log("id: ", id);


//     const docRef = doc(db, c, id);
//     console.log("getDescriptionsById after docRef");

//     // const docSnap = await getDoc(docRef);
//     const docSnap = await getDocWithRetry(docRef);
//     console.log("getDescriptionsById after docSnap");

//     if (!docSnap.exists()) { // Sprawdzanie, czy dokument istnieje
//         throw new Error('Document does not exist.');
//     } else {
//         // console.log("Document data:", docSnap.data());
//         return docSnap.data();
//     } 
// }






export default getDescriptionsById;