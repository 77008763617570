<template>
<!-- <Notification  style="z-index:999"/> -->
  <div
    class="w-full z-10 relative md:w-10/12 pt-6 pb-22 mb-6 mx-auto bg-white rounded-xl shadow-md-top"
    :class="{
      hidden: !isVisible,
      'absolute inset-0 mx-auto animate-slideUp': isVisible && selectedMarker,
      'absolute inset-0 mx-auto animate-slideDown':
        !isVisible && !selectedMarker,
    }"
  >
    <div
      class="flex items-center justify-end mr-3 -mt-3 md:mr-6 md:mt-0 relative"
    >
      <a
        @click="closeCard()"
        class="rounded-full cursor-pointer bg-gray-100 p-2 w-12 h-12 hover:bg-gray-300 transition duration-300 flex justify-center items-center ml-auto"
      >
        <span class="material-icons-round">close</span>
      </a>
    </div>

    <span
      v-if="selectedMarker.status === 'closed'"
      class="tracking-widest text-base text-red-700 uppercase font-bold"
      >zamknięte</span
    >
    <h1
      v-if="selectedMarker.status === 'closed'"
      class="text-3xl sm:text-4xl md:text-5xl font-bold text-black mx-4 -mt-2 py-1 px-3 line-through decoration-red-700"
    >
      {{ selectedMarker.title }}
    </h1>
    <h1
      v-else
      class="text-3xl sm:text-4xl md:text-5xl font-bold text-black mx-4 -mt-2 py-1 px-3"
    >
      {{ selectedMarker.title }}
    </h1>

    <p class="mx-4 my-2">
      <a
        v-if="selectedMarker.cid"
        :href="`https://maps.google.com/?cid=${selectedMarker.cid}`"
        target="_blank"
        class="hover:underline"
      >
        {{ selectedMarker.adres.street }}, {{ selectedMarker.adres.city }}
      </a>
      <span v-else
        >{{ selectedMarker.adres.street }},
        {{ selectedMarker.adres.city }}</span
      >
    </p>

    <a
      v-if="Array.isArray(selectedMarker.type)"
      v-for="tag in selectedMarker.type"
      :key="tag"
      :class="
        tag === 'MUALA'
          ? 'bg-orange-500 font-bold text-sm text-white rounded-full py-1 px-4 mx-1 my-4 inline-block'
          : 'bg-gray-100 text-sm text-slate-600 rounded-full py-1 px-4 mx-1 my-4 inline-block'
      "
    >
      {{ tag }}
    </a>

    <div
      v-if="selectedMarker && selectedMarker.url_pyszne"
      class="flex flex-col justify-center items-center col-auto mt-4 bg-green-100 py-5 px-5 mb-5 sm:w-7/12 w-11/12 mx-auto rounded-2xl border-green-600 border-2"
    >
      <h2 class="text-xl sm:text-xl md:text-2xl font-bold text-black mb-4">
        Dostawa z {{ selectedMarker.title }}
      </h2>
      <p class="text-sm sm:text-sm md:text-base text-green-600 mb-4 -mt-4">
        Przepędź głoda, kliknij zamawiam
      </p>
      <a
        :key="selectedMarker.url_pyszne"
        :href="generateUrl(selectedMarker.url_pyszne)"
        class="bg-green-600 font-bold text-base text-white rounded-xl py-2 px-10 cursor-pointer hover:bg-green-700"
        target="_blank"
      >
        Zamawiam online
      </a>
    </div>



    <div class="pb-40">
      <div v-for="review in selectedMarker.reviews">
        <p class="mx-4 my-2 mb-5 text-black">
          <strong class="mark">&nbsp;{{ review.shortDesc }}&nbsp;</strong>
        </p>

        <div class="max-w-screen-md mx-auto pr-0 pl-0 sm:pr-5 sm:pl-5">
          <div class="aspect-w-16 aspect-h-9 rounded-xl">
            <iframe
              class="sm:rounded-xl"
              :src="`https://www.youtube.com/embed/${review.videoId}${
                review.timestamp ? '?start=' + review.timestamp : ''
              }`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              style="width: 100%; height: 100%"
            >
            </iframe>
          </div>
        </div>
        <p
          class="max-w-screen-sm text-left mx-auto px-4 mb-3 mt-1 text-xs text-gray-300 hyphens-auto"
        >
          Data publikacji filmu: {{ convertDateFormat(review.publishedAt) }}
        </p>

        <p
          class="max-w-screen-sm text-left mx-auto px-4 mb-3 mt-6 hyphens-auto"
        >
          {{ review.text }}
        </p>

        <div class="mb-10">
          <h2 class="text-2xl font-bold text-black mx-4 mt-8 py-1 px-3">
            W rolach głównych
          </h2>
          <ul>
            <li
              v-for="res in inThisVideo(props.allMarkers, review.videoId)"
              class="bg-gray-100 text-sm text-slate-600 rounded-full py-1 px-4 mx-1 my-1 inline-block hover:bg-orange-500 hover:text-white transition duration-200"
            >
              <router-link
                :to="`/${prepareUrlName(res.adres.city)}/${prepareUrlName(
                  res.title
                )}.html`"
                >{{ res.title }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
import { prepareUrlName } from "@/composables/prepareUrlName.js";
import { triggerFiltering } from "@/composables/triggerFiltering";
import { convertDateFormat } from "@/composables/convertDateFormat";
import { applyFilters } from "@/composables/applyFilters.js";
import Notification from '@/components/Notification.vue';


const router = useRouter();

const props = defineProps({
  selectedMarker: Object,
  markers: Array,
  allMarkers: Array,
  center: Object,
  zoom: Number,
  search: String,
  mistakesDict: Object,
  filtersData: {
    type: Array,
    default: () => [],
  },
});

const markers = ref([...props.markers]);

const emits = defineEmits([
  "update:selectedMarker",
  "update:markers",
  "update:center",
  "update:zoom",
  "update:search",
  "update:filtersData",
]);

const isVisible = ref(true);
const lastPosition = ref(null);

const inThisVideo = (data, videoId) => {
  return data.filter(
    (item) =>
      item.reviews && item.reviews.some((review) => review.videoId === videoId)
  );
};

function close() {
  const currentPath = router.currentRoute.value.fullPath.split("/");
  const basePath = `/${currentPath[1]}/`;
  router.push(basePath);
}

const generateUrl = (url) => {
  if (url.includes("pyszne.pl")) {
    const resNameUrl = router.currentRoute.value.fullPath.split("/").pop().replace(".html", "");
    return `https://www.awin1.com/cread.php?awinmid=12109&awinaffid=1643147&clickref=${resNameUrl}&ued=${encodeURIComponent(
      url
    )}`;
  } else if (url.includes("roomservice.pl")) {
    return `${url}?muala.app`;
  } else {
    return url;
  }
};

const closeCard = () => {
  isVisible.value = false;
  lastPosition.value = props.selectedMarker.position;

  setTimeout(() => {
    emits("update:selectedMarker", null);
    isVisible.value = true;
  }, 1260);

  close();

  if (props.search) {
    markers.value = triggerFiltering(
      props.search,
      props.allMarkers,
      props.mistakesDict
    );
    const updatedMarkers = applyFilters(props.filtersData, markers.value);
    emits("update:markers", updatedMarkers);
  } else {
    const updatedMarkers = applyFilters(props.filtersData, props.allMarkers);
    emits("update:markers", updatedMarkers);
  }
};


</script>

<style>
.mark {
  margin: 0 -0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient( to right, rgba(255, 225, 0, 0.1), rgb(255 153 58) 4%, rgb(255 179 0 / 30%) );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
</style>
