// Use useRouter and onMounted from Vue
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';

export const setupAnalytics  = () => {
  const router = useRouter();

  onMounted(() => {
    // Track the initial page load
    gtag('config', 'G-PYJWRQ164Q', {
      page_path: window.location.pathname,
    });

    // Track subsequent page views
    router.afterEach((to, from) => {
      gtag('config', 'G-PYJWRQ164Q', {
        page_path: to.path,
      });
    });
  });
};

