<template>
    <div
      v-show="visible"
      class="fixed top-0 left-0 right-0 bg-black text-white font-bold text-center py-3 transition-transform duration-500 flex justify-center items-center"
      :class="{ 'translate-y-0': visible, '-translate-y-full': !visible }"
    >
      <button @click="closeNotification" class="absolute left-4 text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <span class="bg-clip-text text-transparent bg-gradient-to-r from-[#833ab4] via-[#fd1d1d] to-[#fcb045]">
        Znasz insta muala.app? <a href="https://www.instagram.com" class="underline">Kliknij tu</a>
      </span>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false,
      };
    },
    mounted() {
      setTimeout(() => {
        this.visible = true;
      }, 3000);
    },
    methods: {
      closeNotification() {
        this.visible = false;
      },
    },
  };
  </script>
  