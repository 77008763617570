import { ref, watchEffect } from 'vue';

// firebase imports
import { db } from '../firebase/config';
import { collection, limit, orderBy, onSnapshot, query, where } from 'firebase/firestore';

// const getCollection = (c, q, l, ob) => {
//    const documents = ref(null);

//    // collection reference
//    let colRef = collection(db, c);


//    if (q && l && ob) {
//       // console.log(...q);
//       colRef = query(colRef, where(...q), orderBy('lp', 'desc'), limit(l));
//    } else if (q && l) {
//       // console.log(...q);
//       colRef = query(colRef, where(...q), limit(l));
//    } else if (q) {
//       // console.log(...q);
//       colRef = query(colRef, where(...q));
//    }

//    const unsub = onSnapshot(colRef, (snapshot) => {
//       let results = [];
//       snapshot.docs.forEach((doc) => {
//          results.push({ ...doc.data(), id: doc.id });
//       });

//       documents.value = results;
//    });

//    watchEffect((onInvalidate) => {
//       onInvalidate(() => unsub());
//    });

//    return { documents };
// };






const getCollection = (c, l=0) => {
   const documents = ref(null);

   // Tworzenie referencji do kolekcji z sortowaniem malejącym po "lp"
   const colRef = query(collection(db, c), orderBy('createdAt', 'desc'), limit(l));

   // Subskrybowanie zmian w kolekcji
   const unsub = onSnapshot(colRef, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
         results.push({ ...doc.data(), id: doc.id });
      });

      // Aktualizacja wartości
      documents.value = results;
      console.log("results:", results)
   });

   // Wyczyszczenie subskrypcji przy zmianie
   watchEffect((onInvalidate) => {
      onInvalidate(() => unsub());
   });

   return { documents };
};




// const getCollection = (c, q, l, ob) => {
//    const documents = ref(null);
//    const error = ref(null);

//    // Walidacja nazwy kolekcji
//    if (!c) {
//        error.value = "Invalid collection name";
//        return { documents, error };
//    }

//    // collection reference
//    let colRef = collection(db, c);

//    try {
//        // Walidacja zapytania i limitu
//        if (q && !Array.isArray(q)) {
//            throw new Error("Invalid query format. Expected array.");
//        }
//        if (l && (typeof l !== 'number' || l <= 0)) {
//            throw new Error("Limit must be a positive number.");
//        }

//        if (q && l && ob) {
//            if (!ob.field || !ob.direction) {
//                throw new Error("Invalid orderBy object. Must contain field and direction.");
//            }
//            colRef = query(colRef, where(...q), orderBy(ob.field, ob.direction || 'desc'), limit(l));
//        } else if (q && l) {
//            colRef = query(colRef, where(...q), limit(l));
//        } else if (q) {
//            colRef = query(colRef, where(...q));
//        }

//        const unsub = onSnapshot(colRef, (snapshot) => {
//            let results = [];
//            snapshot.docs.forEach((doc) => {
//                results.push({ ...doc.data(), id: doc.id });
//            });
//            documents.value = results;
//        }, (err) => {
//            console.error("Snapshot error:", err);
//            error.value = err.message;
//        });

//        watchEffect((onInvalidate) => {
//            onInvalidate(() => unsub());
//        });

//    } catch (err) {
//        console.error("Error:", err);
//        error.value = err.message;
//    }

//    return { documents, error };
// };


export default getCollection;
